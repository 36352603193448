import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { OnboardingState } from './onboarding.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([OnboardingState])],
  bootstrap: [],
  exports: [],
})
export class OnboardingStateModule { }
