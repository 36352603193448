import { OnboardingContext, OnboardingStepKey } from './types';

export class ToggleSelectedUsecaseAction {
  static readonly type = '[Onboarding] Toggle Selected Usecase';
  constructor(public usecase: OnboardingStepKey) { }
}

export class AddSelectedUsecaseAction {
  static readonly type = '[Onboarding] Add Selected Usecase';
  constructor(public usecase: OnboardingStepKey) { }
}

export class RemoveSelectedUsecaseAction {
  static readonly type = '[Onboarding] Remove Selected Usecase';
  constructor(public usecase: OnboardingStepKey) { }
}

export class NextStepAction {
  static readonly type = '[Onboarding] Next Step';
}


export class PreviousStepAction {
  static readonly type = '[Onboarding] Previous Step';
}

export class CompleteStepAction {
  static readonly type = '[Onboarding] Complete Step';
  constructor(public stepKey: OnboardingStepKey) { }
}

export class SkipStepAction {
  static readonly type = '[Onboarding] Skip Step';
  constructor(public stepKey: OnboardingStepKey) { }
}

export class SkipOnboardingAction {
  static readonly type = '[Onboarding] Skip Onboarding';
}

export class SetOnboardingContextAction {
  static readonly type = '[Onboarding] Set Context'
  constructor(public contextKey: keyof OnboardingContext, public value: OnboardingContext[keyof OnboardingContext]) { }
}

export class ResetOnboardingAction {
  static readonly type = '[Onboarding] Reset Onboarding'
}
