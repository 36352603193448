export enum OnboardingStepKey {
  USERINFO = 'userinfo',
  USECASES = 'usecases',
  INCIDENT_MANAGEMENT = 'incident-management',
  TASK_MANAGEMENT = 'task-management',
  EXTERNAL_COMMUNICATION = 'external-communication',
  PREVIEW_STATUSPAGE = 'preview-statuspage',
  INTERNAL_COMMUNICATION = 'internal-communication',
  MONITORING = 'service-monitoring',
  ALERTING = 'alerting',
  COMPLETED = 'completed',
}

export interface OnboardingStep {
  enabled: boolean;
  // Tracks whether a step fullfils are requirements to be completed.
  // (e.g. if the user has selected at least one usecase)
  completable?: boolean;
  skippedAt?: Date;
  completedAt?: Date;
}

export interface OnboardingUsecase {
  key: OnboardingStepKey;
  title: string;
  description: string;
  icon: string;
  iconBgColorClass: string;
  iconColorClass: string;
  notDeselectable?: boolean;
}

export interface OnboardingContext {
  teamId?: number;
  serviceId?: number;
  serviceName?: string;
  statuspageUrl?: string;
}