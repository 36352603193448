import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import {
  AddSelectedUsecaseAction,
  CompleteStepAction,
  NextStepAction,
  RemoveSelectedUsecaseAction,
  ResetOnboardingAction,
  SetOnboardingContextAction,
  SkipOnboardingAction,
  SkipStepAction,
  ToggleSelectedUsecaseAction,
} from './onboarding.actions';
import {
  OnboardingContext,
  OnboardingStep,
  OnboardingStepKey,
  OnboardingUsecase,
} from './types';

export interface OnboardingStateModel {
  selectedUsecases: string[];
  steps: Record<Partial<OnboardingStepKey>, OnboardingStep>;
  currentStepKey: OnboardingStepKey;
  availableUsecases: OnboardingUsecase[];
  context: OnboardingContext;
}

const defaults: OnboardingStateModel = {
  selectedUsecases: [OnboardingStepKey.INCIDENT_MANAGEMENT],
  currentStepKey: OnboardingStepKey.USECASES,
  context: {},
  steps: {
    [OnboardingStepKey.USECASES]: {
      enabled: true,
    },
    [OnboardingStepKey.INCIDENT_MANAGEMENT]: {
      enabled: true,
    },
    [OnboardingStepKey.MONITORING]: {
      enabled: false,
    },
    [OnboardingStepKey.EXTERNAL_COMMUNICATION]: {
      enabled: false,
    },
    [OnboardingStepKey.PREVIEW_STATUSPAGE]: {
      enabled: false,
    },
    [OnboardingStepKey.TASK_MANAGEMENT]: {
      enabled: false,
    },
    [OnboardingStepKey.INTERNAL_COMMUNICATION]: {
      enabled: false,
    },
    [OnboardingStepKey.ALERTING]: {
      enabled: false,
    },
    [OnboardingStepKey.USERINFO]: {
      enabled: false,
    },
    [OnboardingStepKey.COMPLETED]: {
      enabled: true,
    },
  },
  availableUsecases: [
    {
      key: OnboardingStepKey.INCIDENT_MANAGEMENT,
      title: 'Incident Management',
      description: 'Streamline incident response and resolution workflows',
      icon: 'pi-bolt',
      iconBgColorClass: 'bg-red-500/20',
      iconColorClass: 'text-red-400',
      notDeselectable: true,
    },
    {
      key: OnboardingStepKey.TASK_MANAGEMENT,
      title: 'Task Management',
      description: 'Assign roles and track incident tasks efficiently',
      icon: 'pi-check-square',
      iconBgColorClass: 'bg-amber-500/20',
      iconColorClass: 'text-amber-400',
    },
    {
      key: OnboardingStepKey.EXTERNAL_COMMUNICATION,
      title: 'External Communication',
      description:
        'Keep your users informed with automated status pages and updates',
      icon: 'pi-megaphone',
      iconBgColorClass: 'bg-blue-500/20',
      iconColorClass: 'text-blue-400',
    },
    {
      key: OnboardingStepKey.INTERNAL_COMMUNICATION,
      title: 'Internal Communication',
      description:
        'Coordinate responses and keep teams aligned during incidents',
      icon: 'pi-send',
      iconBgColorClass: 'bg-purple-500/20',
      iconColorClass: 'text-purple-400',
    },
    {
      key: OnboardingStepKey.MONITORING,
      title: 'Service Monitoring',
      description: 'Proactively detect and prevent service disruptions',
      icon: 'pi-wave-pulse',
      iconBgColorClass: 'bg-green-500/20',
      iconColorClass: 'text-green-400',
    },
    {
      key: OnboardingStepKey.ALERTING,
      title: 'Alert Integration',
      description:
        'Connect external alert sources like Alertmanager for seamless incident creation',
      icon: 'pi-bell',
      iconBgColorClass: 'bg-orange-500/20',
      iconColorClass: 'text-orange-400',
    },
  ],
};

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults,
})
@Injectable()
export class OnboardingState implements NgxsOnInit {
  ngxsOnInit(ctx: StateContext<OnboardingStateModel>) {
    const state = ctx.getState();
    if (!state || Object.keys(state).length === 0) {
      ctx.dispatch(new ResetOnboardingAction());
    }
  }

  @Selector()
  static context(state: OnboardingStateModel) {
    return state.context;
  }

  @Selector()
  static selectedUsecases(state: OnboardingStateModel) {
    return state.selectedUsecases;
  }

  @Selector()
  static currentStep(state: OnboardingStateModel) {
    return [state.currentStepKey, state.steps[state.currentStepKey]];
  }

  @Selector()
  static availableUsecases(state: OnboardingStateModel) {
    return state.availableUsecases;
  }

  static isCompleted(stepId: OnboardingStepKey) {
    return createSelector([this], (state: OnboardingStateModel) => {
      return state.steps[stepId].completedAt !== undefined;
    });
  }

  @Selector()
  static progress(state: OnboardingStateModel) {
    const totalSteps =
      Object.values(state.steps).filter(s => s.enabled).length - 1;
    const completedSteps = Object.values(state.steps).filter(
      s =>
        s.enabled && (s.completedAt !== undefined || s.skippedAt !== undefined)
    ).length;

    return (completedSteps / totalSteps) * 100;
  }

  @Selector()
  static canSkip() {
    return true;
  }

  @Action(ResetOnboardingAction)
  resetOnboarding(ctx: StateContext<OnboardingStateModel>) {
    ctx.setState(defaults);
  }

  @Action(SkipOnboardingAction)
  skipOnboarding(ctx: StateContext<OnboardingStateModel>) {
    // patch all enabled steps with skippedAt
    const state = ctx.getState();
    const steps = Object.keys(state.steps).reduce(
      (acc, key) => {
        const step = state.steps[key as OnboardingStepKey];
        if (
          step.enabled &&
          !step.completedAt &&
          !step.skippedAt &&
          key !== OnboardingStepKey.COMPLETED
        ) {
          acc[key as OnboardingStepKey] = {
            ...step,
            skippedAt: new Date(),
          };
        }
        return acc;
      },
      {} as Record<OnboardingStepKey, OnboardingStep>
    );

    ctx.patchState({
      steps: {
        ...state.steps,
        ...steps,
      },
      currentStepKey: OnboardingStepKey.COMPLETED,
    });
  }

  @Action(CompleteStepAction)
  completeStep(
    ctx: StateContext<OnboardingStateModel>,
    { stepKey }: CompleteStepAction
  ) {
    const state = ctx.getState();
    ctx.patchState({
      steps: {
        ...state.steps,
        [stepKey]: {
          ...state.steps[stepKey],
          completedAt: new Date(),
        },
      },
    });

    return ctx.dispatch(new NextStepAction());
  }

  @Action(SkipStepAction)
  skipStep(
    ctx: StateContext<OnboardingStateModel>,
    { stepKey }: SkipStepAction
  ) {
    const state = ctx.getState();
    ctx.patchState({
      steps: {
        ...state.steps,
        [stepKey]: {
          ...state.steps[stepKey],
          skippedAt: new Date(),
        },
      },
    });

    return ctx.dispatch(new NextStepAction());
  }

  @Action(ToggleSelectedUsecaseAction)
  toggleUsecase(
    ctx: StateContext<OnboardingStateModel>,
    { usecase }: ToggleSelectedUsecaseAction
  ) {
    const state = ctx.getState();
    if (state.selectedUsecases.includes(usecase)) {
      this.unselectUsecase(ctx, { usecase });
    } else {
      this.selectUsecase(ctx, { usecase });
    }
  }

  @Action(AddSelectedUsecaseAction)
  selectUsecase(
    { getState, patchState }: StateContext<OnboardingStateModel>,
    { usecase }: AddSelectedUsecaseAction
  ) {
    const state = getState();
    if (!state.selectedUsecases.includes(usecase)) {
      patchState({
        selectedUsecases: [...state.selectedUsecases, usecase],
      });
    }

    // Add the step for the selected usecase if it doesn't exist yet
    if (usecase === OnboardingStepKey.EXTERNAL_COMMUNICATION) {
      patchState({
        steps: {
          ...state.steps,
          [OnboardingStepKey.PREVIEW_STATUSPAGE]: {
            enabled: true,
          },
          [OnboardingStepKey.EXTERNAL_COMMUNICATION]: {
            enabled: true,
          },
        },
      });
    } else {
      patchState({
        steps: {
          ...state.steps,
          [usecase]: {
            enabled: true,
          },
        },
      });
    }
  }

  @Action(RemoveSelectedUsecaseAction)
  unselectUsecase(
    { getState, patchState }: StateContext<OnboardingStateModel>,
    { usecase }: RemoveSelectedUsecaseAction
  ) {
    const state = getState();
    patchState({
      selectedUsecases: state.selectedUsecases.filter(u => u !== usecase),
    });

    if (usecase === OnboardingStepKey.EXTERNAL_COMMUNICATION) {
      patchState({
        steps: {
          ...state.steps,
          [OnboardingStepKey.PREVIEW_STATUSPAGE]: {
            enabled: false,
          },
          [OnboardingStepKey.EXTERNAL_COMMUNICATION]: {
            enabled: false,
          },
        },
      });
    } else {
      patchState({
        steps: {
          ...state.steps,
          [usecase]: {
            enabled: false,
          },
        },
      });
    }
  }

  @Action(NextStepAction)
  nextStep({ getState, patchState }: StateContext<OnboardingStateModel>) {
    const state = getState();

    const stepKeys = Object.keys(state.steps) as OnboardingStepKey[];
    const currentIndex = stepKeys.indexOf(state.currentStepKey);

    for (let i = currentIndex + 1; i < stepKeys.length; i++) {
      const nextStepKey = stepKeys[i];
      if (state.steps[nextStepKey].enabled) {
        patchState({
          currentStepKey: nextStepKey,
        });
        break;
      }
    }
  }

  @Action(SetOnboardingContextAction)
  setOnboardingContext(
    ctx: StateContext<OnboardingStateModel>,
    { contextKey, value }: SetOnboardingContextAction
  ) {
    ctx.patchState({
      context: {
        ...ctx.getState().context,
        [contextKey]: value,
      },
    });
  }
}
